import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
},
{
    path: '/about',
    name: 'about',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
},
{
    path: '/aboutWMS',
    name: 'aboutWMS',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutWMS.vue')
},
{
    path: '/aboutMES',
    name: 'aboutMES',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutMES.vue')
},
{
    path: '/aboutQMS',
    name: 'aboutQMS',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutQMS.vue')
}, {
    path: '/policy',
    name: 'policy',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/DailView.vue')
}, {
    path: '/agreement',
    name: 'agreement',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/NewView.vue')
}, {
    path: '/map',
    name: 'map',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/MyMap.vue')
}, {
    path: '/app',
    name: 'app',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AppView.vue')
},
{
    path: '/plase',
    name: 'plase',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/PlaseView.vue')
},
{
    path: '/abouts',
    name: 'abouts',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutOur.vue')
},
]

const router = new VueRouter({
    routes
})

export default router