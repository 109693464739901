import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import axios from 'axios'
Vue.use(axios)
// import VueAMap from 'vue-amap'
// Vue.use(VueAMap);
Vue.use(ElementUI)

Vue.config.productionTip = false
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')