<template>
  <div class="home" id="home">
    <!-- 头部固定导航栏开始 -->
    <div class="header">
      <!-- 导航栏版心开始 -->
      <div class="header-center">
        <!-- 左侧logo开始 -->
        <div class="logo">
          <img src="../assets/log.png" alt="" />
          <!-- <div class="zhongwei">中微京创</div> -->
        </div>
        <!-- 左侧logo结束 -->
        <!-- 右侧导航栏开始 -->
        <ul class="nav">
          <li class="style">
            <a href="javascript:;">首页 </a>
          </li>
          <li>
            <a href="javascript:;"
              >产品介绍
              <!-- 弹出框开始 -->
              <div class="hide">
                <router-link to="/about">LIMS系统</router-link>
                <router-link to="/aboutWMS">WMS系统</router-link>
                <router-link to="/aboutMES">MES系统</router-link>
                <router-link to="/aboutQMS">QMS系统</router-link>
              </div>
              <!-- 弹出框结束 -->
            </a>
          </li>
          <li>
            <router-link to="/app">定制开发</router-link>
          </li>
          <li>
            <router-link to="/abouts">关于我们</router-link>
          </li>

          <li>
            <router-link to="/map">联系我们</router-link>
          </li>
          <li>
            <router-link to="/plase">申请合作</router-link>
          </li>
        </ul>
        <!-- 右侧导航栏结束 -->
      </div>
      <!-- 导航栏版心结束 -->
    </div>
    <!-- 头部固定导航栏结束 -->
    <!-- 身体部分开始 -->
    <div class="body">
      <!-- 轮播图部分开始 -->
      <div class="banner">
        <el-carousel :interval="3000" arrow="always" height="555px">
          <el-carousel-item>
            <div class="banner-imageone">
              <h4>
                南京中微京创科技有限公司致力于<br />制药领域和医学检验领域数字化软件系统建设<br />
                基于大数据、SAAS化结合机器视觉、AIOT技术<br />助力制药生产及医学检验领域数字化深度转型
              </h4>
              <!-- <div class="word">
                基于大数据、SAAS化结合机器视觉、AIOT技术<br />助力制药生产及医学检验领域数字化深度转型
              </div> -->
              <div class="actions">
                <router-link to="/plase">立即咨询</router-link>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="banner-imagetwo">
              <!-- <h4>中微京创致力于医学实验室系统的研发</h4>
              <div class="word">
                助力医学实验室与互联网相结合，打造便捷的数字化医学系统
              </div>
              <div class="actions"><a href="">立即咨询</a></div> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 轮播图部分结束 -->
      <!-- 中间详情开始 -->
      <div class="center-body">
        <!-- 企业介绍开始 -->
        <div class="message">
          <p class="message-title">公司简介</p>
          <p class="dest">致力于提供信息化、网络化、智能化的科技产品和服务。</p>
          <!-- 企业详情开始 -->
          <div class="copy-message">
            <div class="left-message">
              <img src="../assets/banner-about1.jpg" alt="" />
            </div>
            <div class="right-message">
              <p class="incroduce">
                南京中微京创科技有限公司坐落于江苏省南京市江宁区科学园芝兰路18号生命科技小镇南区4号楼，作为园区引进的科技型企业，我们致力于基于数字化、信息化、智能化为企业提供专业的、应用层、数据层、采集层的整体解决方案，采用先进的SaaS化、产品化技术及B/S架构，实现人工智能应用软件开发和计算机系统服务。我们期待用可靠易用的交付服务，为每一个企业和用户赋能！
              </p>
              <h4 class="hope">企业愿景</h4>
              <p class="p">
                对员工：培养员工的综合能力，助其成为领袖，构建中微京创。
              </p>
              <p class="p">
                对客户：诚信、靠谱、有担当，追求卓越，帮助更多人摆脱低价值工作。
              </p>
              <p class="p">
                对社会：勇于承担社会责任，回报社区。分享团队协作的经验，让更多的创业团队受益。
              </p>
            </div>
          </div>
          <!-- 企业详情结束 -->
        </div>
        <!-- 企业介绍结束 -->
        <!-- 公司主营业务开始 -->
        <div class="start">
          <p class="message-title">公司主营业务</p>
          <p class="dest">致力于制药领域和医学检验领域数字化软件系统建设</p>
          <!-- 主营业务手风琴开始 -->
          <div class="end">
            <!-- 手风琴左侧 -->
            <ul class="end-left">
              <li :class="reqinx == 0 ? 'actives' : ''" @click="reqinxs(0)">
                LIMS系统定制开发
              </li>
              <li :class="reqinx == 1 ? 'actives' : ''" @click="reqinxs(1)">
                WMS系统定制开发
              </li>
              <li :class="reqinx == 2 ? 'actives' : ''" @click="reqinxs(2)">
                MES系统定制开发
              </li>
              <li :class="reqinx == 3 ? 'actives' : ''" @click="reqinxs(3)">
                QMS系统定制开发
              </li>
            </ul>
            <!-- 手风琴右侧 -->
            <div class="end-right">
              <div v-if="reqinx == 0" class="one">
                <div class="one-left">
                  <!-- 蒙版 -->
                  <div class="mask"></div>
                </div>
              </div>
              <div v-if="reqinx == 1" class="two">
                <div class="two-left"></div>
              </div>
              <div v-if="reqinx == 2" class="there">
                <div class="there-left"></div>
              </div>
              <div v-if="reqinx == 3" class="four">
                <div class="four-left"></div>
              </div>
            </div>
          </div>
          <!-- 主营业务手风琴结束 -->
        </div>
        <!-- 公司主营业务结束 -->
        <!-- 一站式产业互联网解决方案开始 -->
        <div class="internet">
          <p class="inter">一站式产业互联网解决方案</p>
          <div class="mark">
            <div>
              <img src="../assets/duihao.png" alt="" /><span
                >整合资源，创新营销</span
              >
            </div>
            <div>
              <img src="../assets/duihao.png" alt="" /><span
                >简化流程，规范交易</span
              >
            </div>
            <div>
              <img src="../assets/duihao.png" alt="" /><span
                >海量信息，数据安全</span
              >
            </div>
            <div>
              <img src="../assets/duihao.png" alt="" /><span
                >清晰透明，操作流畅</span
              >
            </div>
          </div>
        </div>
        <!-- 一站式产业互联网解决方案结束 -->
        <!-- 卡片展示开始 -->
        <div class="card">
          <ul class="card-center">
            <li class="card-one">
              <div class="icon">
                <img
                  src="https://ccdn.goodq.top/caches/cb1ea158ef5fa2dca3fd21db9ca20df6/aHR0cDovL3d3dy5iaW9saW1zLmNuL3FmeS1jb250ZW50L3VwbG9hZHMvMjAxOS8wNi9hNDBiOThhZWRmMzE4OGNlOWRhOWYyMDU1YWY3ZDRlNS5zdmc_p_p100_p_3D.svg"
                  alt=""
                />
              </div>
              <div class="title">中微京创 SaaS平台版</div>
              <span class="price"
                >具有性价比好,操作便捷,功能多等优点,适合初创小型实验室使用,云端部署,不限地点访问,用户可以DIY各种系统设置,中微京创提供在线指导。</span
              >
            </li>
            <li class="card-two">
              <div class="icon">
                <img
                  src="https://ccdn.goodq.top/caches/cb1ea158ef5fa2dca3fd21db9ca20df6/aHR0cDovL3d3dy5iaW9saW1zLmNuL3FmeS1jb250ZW50L3VwbG9hZHMvMjAxOS8wNi85NTAyNmQyNjlmMWIwYWEzYjY5NDliMTVhODcxMGVkMS5zdmc_p_p100_p_3D.svg"
                  alt=""
                />
              </div>
              <div class="title">中微京创 一站式平台</div>
              <span class="price"
                >具有性价比好，操作便捷，功能多等优点，适合中、小类型实验室使用，支持多种部署方式，中微京创可以进行使用指导，为客户提供专业，快捷的服务。</span
              >
            </li>
            <li class="card-there">
              <div class="icon">
                <img
                  src="https://ccdn.goodq.top/caches/cb1ea158ef5fa2dca3fd21db9ca20df6/aHR0cDovL3d3dy5iaW9saW1zLmNuL3FmeS1jb250ZW50L3VwbG9hZHMvMjAxOS8wNi8xNzllZWM0NzUyYzg0NDc3YjZmODA1YWU0MDcwOGFhNzEuc3Zn.svg"
                  alt=""
                />
              </div>
              <div class="title">中微京创 旗舰版</div>
              <span class="price"
                >具有功能性广等优点，可支持大型实验室使用，支持个性化量身定制，各类验证支持，接口平台，并且中微京创拥有专业的咨询、开发技术、项目实施、支持团队从咨询到上线全程支持。为客户提供专业，便捷的服务。</span
              >
            </li>
            <li class="card-four">
              <div class="icon">
                <img
                  src="https://ccdn.goodq.top/caches/cb1ea158ef5fa2dca3fd21db9ca20df6/aHR0cDovL3d3dy5iaW9saW1zLmNuL3FmeS1jb250ZW50L3VwbG9hZHMvMjAxOS8wNi9jZmY3Nzg1NDc0NzAzODc3NjJkYzFlYTZiZmFkMTEwZS5zdmc_p_p100_p_3D.svg"
                  alt=""
                />
              </div>
              <div class="title">不断更新的交付方式</div>
              <span class="price"
                >中微京创不断贴合实验室与市场的实际需求将推出更好的交付方式</span
              >
            </li>
          </ul>
        </div>
        <!-- 卡片展示结束 -->
        <!-- 流程图开始 -->
        <div class="process">
          <p class="title-process">选择我们的理由</p>
          <p class="dist">专业的软件定制开发项目管理体系，让您更放心</p>
          <div class="layui">
            <div class="reason">
              <div class="image">
                <img src="../assets/icon-excellent1.png" alt="" />
              </div>
              <div class="reason-right">
                <div class="reason-title">卓越品质、技术领先</div>
                <div class="reason-dest">
                  技术创新、模式创新，始终聚焦互联网+行业解决方案，为您带来无尽的价值
                </div>
              </div>
            </div>
            <div class="reason">
              <div class="image">
                <img src="../assets/icon-update2.png" alt="" />
              </div>
              <div class="reason-right">
                <div class="reason-title">免费主动升级服务</div>
                <div class="reason-dest">
                  采用互联网迭代开发模式，快速响应用户需求,一次购买终身主动升级，软件功能永不落伍
                </div>
              </div>
            </div>
            <div class="reason">
              <div class="image">
                <img src="../assets/icon-security3.png" alt="" />
              </div>
              <div class="reason-right">
                <div class="reason-title">高效运维、安全稳定</div>
                <div class="reason-dest">
                  银行级安全防护标准，加密服务、设备绑定等机制，确保数据安全
                </div>
              </div>
            </div>
            <div class="reason">
              <div class="image">
                <img src="../assets/icon-service4.png" alt="" />
              </div>
              <div class="reason-right">
                <div class="reason-title">7*24小时放心服务</div>
                <div class="reason-dest">
                  免费试用、免费培训、零风险，专属售后团队服务高效、专业
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 流程图结束 -->
      </div>
      <!-- 中间详情结束 -->
    </div>
    <!-- 身体部分结束 -->
    <!-- 足部开始 -->
    <div class="footer">
      <!-- 足部上册开始 -->
      <div class="footer-top">
        <!-- 版心开始 -->
        <div class="footer-topCenter">
          <!-- 版心左侧 -->
          <div class="left-center">
            <div class="understand">了解更多</div>
            <div class="contect">
              立即与我们联系，在线咨询产品和数字化解决方案并预约试用
            </div>
            <router-link to="/plase"><span>立即申请试用</span></router-link>
          </div>
          <!-- 版心右侧 -->
          <div class="right-center">
            <div class="service">直接致电咨询</div>
            <div class="answer">
              您也可以直接致电咨询，我们的顾问将为您耐心解答
            </div>
            <!-- <div class="telephone">13813387629</div> -->
          </div>
        </div>
        <!-- 版心结束 -->
      </div>
      <!-- 足部上册结束 -->
      <!-- 足部下册开始 -->
      <div class="footer-bottom">
        <!-- 版心开始  -->
        <div class="footer-bottomCenter">
          <!-- 版心左侧 开始 -->
          <div class="bottom-left">
            <div class="server">服务咨询</div>
            <!-- <div class="server-phone">13813387629</div> -->
            <div class="server-all">商务合作:wangzhong@zwjctech.com</div>
            <!-- <div class="server-technology">技术支持:support@zhongwei.tech</div> -->
          </div>
          <!-- 版心左侧 结束 -->
          <!-- 版心右侧 开始 -->
          <div class="bottom-right">
            <div class="wisdom">
              产品

              <router-link to="/about">LIMS系统</router-link>
              <router-link to="/aboutWMS">WMS系统</router-link>
              <router-link to="/aboutMES">MES系统</router-link>
              <router-link to="/aboutQMS">QMS系统</router-link>
            </div>
            <div class="customized">
              定制开发
              <router-link to="/app">系统定制</router-link>
              <router-link to="/app">APP定制</router-link>
              <router-link to="/app">小程序定制</router-link>
            </div>
            <div class="about">
              关于

              <router-link to="abouts">关于我们</router-link>
              <router-link to="map">联系我们</router-link>
            </div>
            <div class="official">
              官方网站
              <a href="www.zwjctech.com">www.zwjctech.com</a>
            </div>
          </div>
          <!-- 版心右侧 结束 -->
        </div>
        <!-- 版心结束  -->
      </div>
      <!-- 足部下册结束 -->
      <!-- 足部公司信息开始 -->
      <div class="company">
        <p class="line">
          咨询热线：13813387629 邮箱：wangzhong@zwjctech.com
          地址：江苏省南京市江宁区科学园芝兰路18号生命科技小镇南区4号楼
        </p>
        <p class="keep">
          Copyright ©2021-2022 zwjctech.com All Rights Reserved
          南京中微京创科技有限公司版权所有<a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011502011685"
            style="position: relative"
            ><img
              src="../assets/备案图标.png"
              alt=""
              style="position: absolute; top: -4px; left: -1px"
            /><span style="margin-left: 20px"
              >苏公网备案号:32011502011685号</span
            ></a
          ><a href="https://beian.miit.gov.cn">苏ICP备2022031846号</a>
        </p>
        <!-- <p class="plic">
          <img src="../assets/下载.png" alt="" /><a>苏ICP备36010902000406号 </a>
        </p> -->
      </div>
      <!-- 足部公司信息结束 -->
    </div>
    <!-- 足部结束 -->
  </div>
</template>
<style>
html,
body {
  font-family: "微软雅黑";
}
</style>
<style scoped>
#home {
  width: 100%;
}

/* 头部固定导航栏开始 */
.header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 70px;
  background-color: black;
  opacity: 1;
  z-index: 5;
}

.header-center {
  width: 1200px;
  height: 70px;
  margin: 0px auto;
}

.logo {
  width: 300px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  position: relative;
}

.logo img {
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: right;
  width: 50%;
  height: 70px;
  background-color: black;
}

.zhongwei {
  left: 135px;
  top: 0px;
  position: absolute;
  width: 180px;
  height: 70px;
  color: blue;
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  line-height: 70px;
}

/* 头部右侧导航栏 */
.nav {
  width: 760px;
  height: 70px;
  float: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-size: 16px;
}

.nav li {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav li a {
  height: 100%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav li:hover {
  transition: 0.2s all linear;
  /*设置过度时间*/
  border-bottom: 2px solid red;
}

.nav .style {
  border-bottom: 2px solid red;
}

.nav li > a:hover .hide {
  /* animation:fadenum 3s infinite; */
  width: 150px;
  display: block;
  animation: hideIndex 0.3s;
}

@keyframes hideIndex {
  0% {
    background-color: #fff;
    opacity: 0;
    transform: translate(0px, 20px);
  }

  100% {
    background-color: #fff;
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* 弹出框开始 */
.hide {
  display: none;
  width: 150px;
  z-index: 5;
  box-shadow: -2px 2px 2px #888888;
  background-color: #fff;

  position: absolute;
  top: 68px;
  left: -35px;
  text-align: center;
}

.nav .hide > a {
  margin: 10px 0px;
  width: 100%;
  color: black;
  font-size: 16px;
  float: left;
  text-align: center;
}

.nav .hide > a:hover {
  color: blue;
}

/* 头部固定导航栏结束 */
/* 身体部分开始 */
.body {
  width: 100%;
}

/* 轮播图开始 */
.banner {
  width: 100%;
  height: 555px;
}

.banner-imageone {
  width: 100%;
  height: 100%;
  background-image: url(../assets/LIMSbanner.jpg);
  padding-top: 180px;
  background-repeat: no-repeat;
}

.banner-imagetwo {
  padding-top: 180px;
  width: 100%;
  height: 100%;
  background-image: url(../assets/首页000.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.banner-imageone > h4 {
  width: 55%;
  color: #fff;
  font: 25px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.banner-imagetwo > h4 {
  width: 55%;
  color: #fff;
  font: 35px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.word {
  width: 55%;
  height: 36px;
  margin: 24px 0px 0px;
  color: #fff;
  font: 22px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

.actions {
  width: 55%;
  height: 40px;
  color: #000;
  margin: 40px 0px 0px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.actions > a {
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  width: 124px;
  height: 40px;
  color: #fff;
  border: 1px solid #fff;
}

.actions > a:hover {
  background-color: #fff;
  color: black;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/* 轮播图结束 */
/* 中间详情开始 */
.center-body {
  width: 100%;
}

/* 企业介绍开始 */
.message {
  width: 1200px;
  height: 654px;

  margin: 0px auto;
  padding: 70px 0px;
}

.message-title {
  width: 100%;
  height: 48px;
  color: #333;
  font-size: 30px;
}

.dest {
  margin: 16px 0px 40px;
  color: #666;
  font-size: 16px;
}

/* 企业详情开始 */
.copy-message {
  width: 100%;
  height: 384px;

  display: flex;
}

.left-message {
  width: 590px;
  height: 100%;
  padding: 0px 50px 0px 0px;
}

.left-message > img {
  width: 100%;
  height: 100%;
}

.right-message {
  width: 560px;
  height: 100%;
}

.incroduce {
  width: 100%;
  height: 200px;
  text-align: left;
  margin: 20px 0px 0px;
  color: #000;
  font-size: 18px;
  line-height: 30px;
  font-family: "微软雅黑";
}

.hope {
  margin-top: 20px;
  font-size: 20px;
  color: #333;
  text-align: left;
}

.p {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #333;
  margin: 15px 0px 0px 0px;
}

/* 企业介绍结束 */
/* 公司主营业务开始 */
.start {
  margin: 0px auto;
  width: 1200px;
  height: 600px;
}
/* 主营业务手风琴开始 */
.end {
  width: 100%;
  height: 494px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* 手风琴左侧 */
.end-left {
  width: 20%;
  height: 100%;
  background-color: #d3dce6;
}
.end-left > li {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}
/* .end-left > li:hover {
  color: red;
  background-color: blue;
} */

/* 手风琴右侧 */
.end-right {
  width: 80%;
  height: 100%;
}
.one-left {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 79, 128);
  background: url(../assets/手风琴LIMS.jpg) no-repeat;
  background-size: 100% 100%;
}
.two-left {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 79, 128);
  background: url(../assets/手风琴WMS.jpg) no-repeat;
  background-size: 100% 100%;
}
.there-left {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 79, 128);
  background: url(../assets/手风琴MES.webp) no-repeat;
  background-size: 100% 100%;
}
.four-left {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 79, 128);
  background: url(../assets/手风琴QMS.webp) no-repeat;
  background-size: 100% 100%;
}
/* 蒙版 */
/* .mask {
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
} */
.end-right > div {
  width: 100%;
  height: 100%;
  background-color: skyblue;
  position: relative;
}
.actives {
  background-color: rgb(241, 233, 233) !important;
  color: #fff;
  border-right: 2px solid red;
}
.styles {
  display: block !important;
}
/* 主营业务手风琴结束 */

/* 公司主营业务结束 */

.internet {
  margin: 0px auto;
  width: 1200px;
  height: 174px;

  padding: 54px 0px 24px 0px;
}

.inter {
  width: 100%;
  font-size: 32px;
  color: black;
}

.mark {
  width: 100%;
  display: flex;
  justify-content: space-around;

  margin: 16px 0px 0px;
}

.mark > div {
  display: flex;
  align-content: center;
}

.mark span {
  height: 100%;
  line-height: 32px;
  vertical-align: top;
  margin-left: 5px;
}

/* 卡片展示开始 */
.card {
  width: 100%;
  height: 378px;
  padding: 25px 0px;
}

.card-center {
  margin: 0px auto;
  width: 1200px;
  height: 100%;

  display: flex;
  justify-content: space-between;
}

.card-center > li {
  transition: all 1s linear;
  width: 251px;
  height: 328px;
  background-color: #fff;
  box-shadow: 10px 10px 10px 10px;
  padding: 30px 30px 35px;
  /* animation-name: ball;
  animation-duration: 2s; */
}

.card-center > li:hover {
  transform: scale(1.05);
  transition: all 0.5s linear;
}

/* @keyframes ball {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
} */

.icon {
  width: 191px;
  height: 55px;
  text-align: left;
}

.icon > img {
  width: 55px;
  height: 55px;
}

.title {
  width: 100%;
  text-align: left;
  font-family: fa1f469b331c5622615f44cf8abf39fb7;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #004767;
  margin: 28px 0px 1px;
  font-size: 16px;
}

.price {
  display: block;
  width: 100%;
  color: #022839;
  font-size: 12px;
  text-align: left;
  margin-top: 20px;
}

/* 流程图开始 */
.process {
  width: 1200px;
  height: 662px;

  padding: 64px 0px;
  color: #000;
  margin: 0px auto;
}

.title-process {
  color: #333;
  font-size: 30px;
}

.dist {
  margin: 16px 0px 40px;
  font-size: 16px;
  color: #666;
}

.layui {
  width: 100%;
  height: 408px;
  color: #000;
  font-size: 14px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
}

.reason {
  width: 580px;
  height: 184px;
  box-shadow: 0 8px 24px 0 rgb(46 61 101 / 10%);
  display: flex;
}

.reason:hover {
  box-shadow: 0 8px 24px 0 rgb(29 80 163 / 20%);
}

.image {
  width: 200px;
  height: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image > img {
  width: 100px;
  height: 96px;
}

.reason-right {
  width: 380px;
  height: 184px;
  font-size: 14px;
  padding: 32px 16px 0px;
}

.reason-title {
  width: 348px;
  height: 36px;
  color: #000;
  font-size: 24px;
  display: flex;
  justify-content: left;
}

.reason-dest {
  width: 100%;
  height: 56px;
  color: #666;
  padding: 16px 0px 0px;
  text-align: left;
}

/* 流程图结束 */
/* 卡片展示结束 */

/* 中间详情结束 */
/* 身体部分结束 */
/* 足部开始 */
.footer {
  margin-top: 20px;
  width: 100%;
}

/* 足部结束 */
/* 足部上册开始 */
.footer-top {
  width: 100%;
  height: 200px;
  background: url(../assets/footer-touch-bg.jpg) no-repeat;
}

/* 版心开始 */
.footer-topCenter {
  padding: 20px 0px;
  box-sizing: border-box;
  width: 1200px;
  height: 100%;
  margin: 0px auto;
  display: flex;
}

/* 版心结束 */
/* 版心左侧 */
.left-center {
  width: 50%;
  height: 100%;

  text-align: left;
  border-right: 1px solid rgb(199, 194, 194);
}

.understand {
  font-size: 24px;
  margin-top: 10px;
  color: #fff;
}

.contect {
  font-size: 14px;
  margin: 5px 0px 0px;
  color: #fff;
}

.left-center > a {
  display: block;
  width: 232px;
  height: 42px;
  margin: 30px 0px 0px;
  border: 1px solid #fff;
  line-height: 42px;
  text-align: center;
  color: #fff;
}

.left-center > a:hover {
  background-color: #fff;
  color: #99a9bf;
}

.left-center > a > span::after {
  margin-left: 5px;
  content: ">";
  color: #fff;
}

/* 版心右侧 */
.right-center {
  width: 50%;
  height: 100%;

  padding: 0px 0px 0px 90px;
  text-align: left;
}

.service {
  margin: 10px 0px 0px;
  font-size: 24px;
  color: #fff;
}

.answer {
  font-size: 14px;
  color: #fff;
  margin: 5px 0px 0px;
}

.telephone {
  font-size: 30px;
  color: #fff;
  margin: 20px 0px 0px;
}

/* 足部上册结束*/
/* 足部下册开始 */
.footer-bottom {
  width: 100%;
  height: 230px;
  background-color: black;
}

/* 版心 */
.footer-bottomCenter {
  width: 1200px;
  height: 100%;
  padding: 20px 0px 30px;
  margin: 0px auto;
  border-bottom: #aba7b8 1px solid;
}

.footer-bottomCenter > div {
  padding: 20px 0px;
}

.bottom-left {
  width: 28%;
  height: 100%;
  float: left;

  border-right: 1px solid rgb(78, 74, 74);
  text-align: left;
  color: #fff;
}

.server {
  font-size: 14px;
}

.server-phone {
  font-size: 30px;
  margin: 10px 0px 20px;
}

.server-all {
  color: #aba7b8;
  font-size: 14px;
}

.server-technology {
  color: #aba7b8;
  font-size: 14px;
}

.bottom-right {
  width: 72%;
  height: 100%;
  float: right;

  text-align: left;
  display: flex;
  justify-content: space-around;
}

.bottom-right > div {
  color: #fff;
  font-size: 14px;
}

.wisdom > a {
  margin: 5px 0px;
  color: #aba7b8;
  display: block;
  cursor: pointer;
}

.wisdom > a:hover {
  color: #fff;
  cursor: pointer;
}

.customized > a {
  margin: 5px 0px;
  color: #aba7b8;
  cursor: pointer;
  display: block;
}

.customized > a:hover {
  color: #fff;
}

.about > a {
  margin: 5px 0px;
  cursor: pointer;
  display: block;
  color: #aba7b8;
}

.about > a:hover {
  color: #fff;
}

.official > a {
  display: block;
  cursor: pointer;
  margin-top: 5px;
  color: #fff;
}

/* 足部下册结束 */
/* 公司信息
 */
.company {
  width: 100%;
  height: 172px;
  background-color: black;
  padding: 30px 0px 10px;
}

.line {
  color: #aba7b8;
  font-size: 12px;
  margin: 0px 0px 20px;
}

.keep {
  color: #aba7b8;
  font-size: 12px;
  margin: 0px 0px 20px;
}

.keep > a {
  color: #aba7b8;
  margin-left: 5px;
}

.keep > a:hover {
  color: #fff;
}

.plic {
  color: #aba7b8;
  font-size: 12px;
  line-height: 23px;
}

.plic > a {
  vertical-align: top;
  margin-left: 5px;
}

.plic > a:hover {
  color: #fff;
}
</style>
<script>
export default {
  data() {
    return {
      reqinx: 0,
    };
  },
  created() {},
  methods: {
    reqinxs(index) {
      this.reqinx = index;
    },
  },
};
</script>
